import { apiAxios } from "api";
import FullPageSpinner from "components/full-page-spinner/full-page-spinner";
import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useToast } from "./toast-context";

const AuthContext = createContext();

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) throw new Error(" useAuthContext must be used within a AuthProvider");
  return context;
}

function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const { addToast } = useToast();

  // Clear error state on route change
  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  // Check for active user session
  useEffect(() => {
    async function getCurrentSessionAsync() {
      // setLoading(true);
      try {
        const { data } = await apiAxios.get(`/api/auth/me`);
        return setUser(data.data);
      } catch (err) {
        return {};
      } finally {
        setInitialLoading(false);
        // setLoading(false);
      }
    }
    getCurrentSessionAsync();
  }, []);

  const login = async (formData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await apiAxios.post(`/api/auth/login`, formData);
      setUser(data.data);
      history.push("/");
      addToast("success", data.message);
    } catch (e) {
      addToast("error", e.response ? e.response.data.error : e.message);
      setError(e.response ? e.response.data.error : e.message);
    } finally {
      setLoading(false);
    }
  };

  const signUp = async (formData) => {
    setLoading(true);
    try {
      const { data } = await apiAxios.post("/api/auth/sign-up", formData);
      setUser(data.data);
      history.push("/");
      addToast("success", data.message);
    } catch (e) {
      addToast("error", e.response ? e.response.data.error : e.message);
      setError(e.response ? e.response.data.error : e.message);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    await apiAxios.delete("/api/auth/logout");
    setUser(null);
    history.push("/login");
  };

  const updateUserState = async (updatedUser) => setUser(updatedUser);

  const memoedValues = useMemo(
    () => ({ user, loading, error, login, signUp, logout, updateUserState }),
    [user, loading, error]
  );

  return (
    <AuthContext.Provider value={memoedValues}>
      {!initialLoading && children}
      {initialLoading && <FullPageSpinner />}
    </AuthContext.Provider>
  );
}

export { useAuth, AuthProvider };

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
