/* eslint-disable react/prop-types */
import { createContext, useReducer, useContext, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

const ToastContext = createContext();

const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) throw new Error(" useToast must be used within a ToastProvider");
  return context;
};

const toastReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };

    case "REMOVE_TOAST":
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.payload),
      };
    default:
      throw new Error(`${action.type} is not defined`);
  }
};

const ToastProvider = ({ children }) => {
  const [toastState, toastDispatch] = useReducer(toastReducer, { toasts: [] });

  const addToast = (type, message) => {
    const id = uuidv4();
    toastDispatch({ type: "ADD_TOAST", payload: { id, type, message } });

    setTimeout(() => {
      toastDispatch({ type: "REMOVE_TOAST", payload: id });
    }, 4000);
  };

  const removeToast = (id) => toastDispatch({ type: "REMOVE_TOAST", payload: id });

  const memoedValues = useMemo(() => ({ toastState, addToast, removeToast }), [toastState]);

  return <ToastContext.Provider value={memoedValues}>{children}</ToastContext.Provider>;
};

export { useToast, ToastProvider };
