import Toast from "components/toast/toast";
import { ToastProvider } from "contexts/toast-context";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./contexts/auth-context";
import "./index.css";

ReactDOM.render(
  <Router>
    <ToastProvider>
      <AuthProvider>
        <Toast />
        <App />
      </AuthProvider>
    </ToastProvider>
  </Router>,
  document.getElementById("root")
);
