import axios from "axios";

const API_BASE_URL = {
  development: "http://localhost:5000",
  production: "https://server.cdlreferral.com",
}[process.env.NODE_ENV];

const apiAxios = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

const registerCandidate = (formData) => apiAxios.post("/api/candidates", formData);

const updateUser = (id, formData) => apiAxios.patch(`/api/users/${id}`, formData);

const sendMessage = (formData) => apiAxios.post("/api/twilio/send-message", formData);

const sendEmail = (config) => apiAxios.post("/api/twilio/send-email", config);

const requestPasswordReset = (email) => apiAxios.post("/api/auth/password-reset-request", { email });

const passwordReset = (token, userId, password) =>
  apiAxios.patch("/api/auth/password-reset", { token, userId, password });

const getUserCandidates = (id) => apiAxios.get(`/api/users/${id}/candidates`);

export {
  API_BASE_URL,
  apiAxios,
  registerCandidate,
  updateUser,
  sendMessage,
  sendEmail,
  requestPasswordReset,
  passwordReset,
  getUserCandidates,
};
