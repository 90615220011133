import FullPageSpinner from "components/full-page-spinner/full-page-spinner";
import { useAuth } from "contexts/auth-context";
import React, { lazy, Suspense, useEffect } from "react";
import "tabulator-tables/dist/css/tabulator.min.css";
import ReactGA from "react-ga";
import "App.css";
import { useLocation } from "react-router-dom";

const AuthenticatedApp = lazy(() => import("./authenticated-app"));
const UnauthenticatedApp = lazy(() => import("./unauthenticated-app"));

const App = () => {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-211283992-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  console.log(location, window.location.pathname + window.location.search);

  return (
    <Suspense fallback={<FullPageSpinner />}>{auth.user ? <AuthenticatedApp /> : <UnauthenticatedApp />}</Suspense>
  );
};

export default App;
